body {
    padding: 0px;
    margin: 0px;
    width: 100%;
}
.login-container {
    width: 100%;
    height: 100%;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-container .container-inner {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-container .container-inner .header {
    width: 100%;
    height: 300px;
    background-color: rgb(30, 178, 247);
    background-size: cover;
}
.login-container .container-inner .header img {
    width: 240px;
    margin-left: 20px;
    margin-top: 20px;
}
.login-container .container-inner .form-wrap {
    width: 100%;
    max-width: 500px;
    margin-top: 30px;
    box-sizing: border-box;
    padding: 15px;
}
.login-container .container-inner .form-wrap h1 {
    color: #616161;
}
.login-container .container-inner .form-wrap .error-message {
    color: #fd0202;
}
.container .container-inner .form-wrap form{
    width: 100%;
}
.login-container .container-inner .form-wrap form input{
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
}
.login-container .container-inner .form-wrap form button {
    width: 250px;
    height: 50px;
    background-color: #242424;
    color: #f2f2f2;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 10px 30px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}

/*
SPINNERS
*/
.lds-dual-ring {
    /* change color here */
    color: #fff;
  }
  .lds-dual-ring,
  .lds-dual-ring:after {
    box-sizing: border-box;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 4px;
    border-radius: 50%;
    border: 3px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }