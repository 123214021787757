/* body {
    padding: 0px;
    margin: 0px;
} */
.track-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.container-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container-inner-650 {
    width: 100%;
    max-width: 650px;
    padding: 15px;
    box-sizing: border-box;
}
.section-one {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E5E5;
    padding: 20px;
    box-sizing: border-box;
}

.section-one img {
    width: 300px;
}
.section-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f780;
    margin-top: 0px;
    box-sizing: border-box;
}
.section-two h1 {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 30px; 
    color: #333;
    line-height: 1.5;
}
.section-four {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.section-four img {
    width: 180px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.section-four h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px; 
    font-family: "Roboto", sans-serif;
    color: #333333;
    line-height: 29px;
    letter-spacing: 1.17px;
    margin-bottom: 0px;
}
.section-four .four-p {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #333333;
    line-height: 29px;
    letter-spacing: 1.17px;
    margin-top: 0px;

}
.section-four .delivery-details {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
}
.section-four .item {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.section-four .item .item-field {
    width: calc(50% - 10px);
    margin-right: 10px;
}
.section-four .item .item-value {
    width: calc(50% - 10px);
    margin-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 12px; 
    line-height: 22px; 
    letter-spacing: 1px;
}
.section-four .item .item-field {
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto", sans-serif;
    font-size: 12px; 
    line-height: 22px; 
    letter-spacing: 1px;
}
.section-four .item .item-field span {
    text-align: right;
    text-transform: uppercase;
    font-weight: 700; 
    color: #333;
}
.section-four .item .item-value span {
    text-align: left;
    font-weight: 300; 
    color: #333;
}
.section-four .item .item-value .tracking-number-value {
    color: #007AB7;
    text-decoration: underline;
}
.section-five {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.section-five p {
    font-family: "Roboto", sans-serif;
    font-size:14px; 
    line-height: 22px; 
    font-weight: 300; 
    color: #333;
}